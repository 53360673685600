import React from 'react';
import { Center } from "@chakra-ui/react"
import { motion } from "framer-motion"

const MotionBox = motion(Center)

export default function LoadingScreen() {
  return (
    <MotionBox
      width="100px"
      height="100px"
      bg="teal.200"
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}

      animate={{
        scale: [1, 3, 3, 1, 1],
        rotate: [0, 0, 270, 270, 0],
        borderRadius: ["20%", "20%", "50%", "50%", "20%"]
      }}
      transition={{
        duration: 2,
        ease: "easeInOut",
        times: [0, 0.2, 0.5, 0.8, 1],
        loop: Infinity,
        repeatDelay: 1
      }}
    />
  )
}