import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Container from '../components/Container';
import LoadingScreen from '../components/LoadingScreen';
import Header from '../components/Header';
import {useTranslation} from 'gatsby-plugin-react-i18next';

import SEO from '../components/SEO';

import {
  chakra,
  Box,
  Image,
  Heading,
  Text,
  Link,
  VStack,
  HStack,
  Badge,
  useColorModeValue,
  Divider,
  Avatar,
  UnorderedList,
  OrderedList,
  ListItem
} from '@chakra-ui/react';
import SpotifyPlayer from 'react-spotify-player';
import parser, { domToReact } from 'html-react-parser';
import * as styles from  '../components/hero.module.css'

const BlogPostTemplate = (props) => {
  const { data, location } = props;
  const post = data.contentfulBlogPost;
  const siteTitle = data.site?.siteMetadata.title;
  const author = post.author;
  const spotifyTheme = useColorModeValue("black", "white");
  const [showLoading, setShowLoading] = useState(false);
  const {t} = useTranslation();
  const fromSerendipityButton = location.state?.fromSerendipityButton;

  const size = {
    width: '100%',
    height: 250,
  };

  useEffect(() => {
    if(fromSerendipityButton){
      setShowLoading(true);
      setTimeout(() => {
        setShowLoading(false);
      }, 4000)
    }
  }, [fromSerendipityButton]);

  const renderOptions = {
    replace: domNode => {
      if (domNode?.attribs === undefined) {
        return;
      }
      if(domNode.name){
        let MyComponent = chakra(domNode.name);
        let props = {
          mb: "3"
        };
        if(domNode.name === "h1"){
          props = {
            as: "h1",
            size: "xl",
            mt: "4",
            mb: "1"
          }
          MyComponent = Heading;
        }
        else if(domNode.name === "img"){
          props = {
            as: "img",
            size: "xl",
            mt: "3",
          }
          return <>
          <Image as={GatsbyImage} {...props} {...domNode.attribs}/>
          <Text fontSize="sm" pt="1" pb="3" dangerouslySetInnerHTML={{__html: domNode.attribs.alt}}></Text>
          </>;
        }
        else if(domNode.name === "h2"){
          props = {
            as: "h2",
            size: "lg",
            mt: "4",
            mb: "1"
          }
          MyComponent = Heading;
        }
        else if(domNode.name === "h3"){
          props = {
            as: "h3",
            size: "lg",
            mt: "3",
            mb: "1",
          }
          MyComponent = Heading;
        }
        else if(domNode.name === "p"){
          props = {
            fontSize: "lg",
            mb: "3"
          }
          MyComponent = Text;
        }
        else if(domNode.name === "a"){
          props = {
            color: "teal.400",
            isExternal: true,
          }
          MyComponent = Link;
        }
        else if(domNode.name === "ul"){
          MyComponent = UnorderedList;
        }
        else if(domNode.name === "ol"){
          MyComponent = OrderedList;
        }
        else if(domNode.name === "li"){
          props = {
            mb: "1"
          }
          MyComponent = ListItem;
        }
        return (
          <MyComponent {...domNode.attribs} {...props}>
            {domToReact(domNode.children, renderOptions)}
          </MyComponent>);
      }
      return <></>
    }
  }

  const markDownReactElement = parser(
    post.body.childMarkdownRemark.html,
    renderOptions
  )

  return (
    <>
      {showLoading && 
        <Container>
          <VStack direction="column" align="center" justify="center" h="100vh" spacing="10">
            <LoadingScreen/>
            <Heading textAlign="center">
              {t("loadingSerendipity")}
            </Heading>
          </VStack>
        </Container>
      }
      {!showLoading &&
        <Container>
          <Header/>
          <SEO
            title={`${post.title} | ${siteTitle}`}
            description={post.description || ''}
            image={post.heroImage.gatsbyImageData.images.fallback.src}
            pathname={`${post.node_locale}/${post.slug}`}
            article
          />
          <Box>
             <Box className={styles.hero}>
              <Image
                className={styles.heroImage}
                as={GatsbyImage}
                alt={post.title}
                image={post.heroImage.gatsbyImageData}
              />
            </Box>
            
            <Box
              p="5"
              display="flex"
              flexDir="column"
              alignItems="center"
            >
              <VStack
                align="flex-start"
                spacing="5"
                w={["100%", "100%", "70%"]}
                textAlign="start"
              >
                <HStack justify="center">
                  <Text fontSize="lg">
                    {post.publishDate}
                  </Text>
                  {post.tags?.map(tag => (
                    <Badge>
                      {tag}
                    </Badge>
                  ))}
                </HStack>
                <Heading
                  size="2xl"
                  pb="5"
                >
                  {post.title}
                </Heading>
                {post?.spotify && 
                  <SpotifyPlayer
                    uri={post?.spotify}
                    size={size}
                    view="coverart"
                    theme={spotifyTheme}
                  />
                }
              </VStack>
              <Box 
                textAlign="start"
                w={["100%", "100%", "70%"]}
              >
                {markDownReactElement}
              </Box>
              <Divider w={["100%", "100%", "70%"]} my="5"/>
              <Box
                w={["100%", "100%", "70%"]}
              >
                <HStack spacing="3">
                  <Avatar name={author?.name} src={author.image.gatsbyImageData.images.fallback.src}/>
                  <VStack align="start" spacing="1">
                    <Text fontSize="md" fontWeight="bold">{author?.name}</Text>
                    <Text fontSize="sm">{author?.title}</Text>
                  </VStack>
                </HStack>
              </Box>
            </Box>
          </Box>
        </Container>
      }
    </>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query ($slug: String!, $language: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM DD, YYYY", locale: $language)
      heroImage {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      author {
        name
        title
        image{
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      tags
    }
    site {
      siteMetadata {
        title
      }
    }
    locales: allLocale(filter: {ns: {in: ["header", "blogPost"]}, language: {eq: $language}})  {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
